import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewChecked,
  SimpleChanges,
  OnChanges,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { DetailRoutines, Routines, SelectedClient } from '@core/interfaces/selected-client';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-binnacle-table',
  templateUrl: './binnacle-table.component.html',
  styleUrls: ['./binnacle-table.component.scss'],
})
export class BinnacleTableComponent implements AfterViewChecked, OnChanges {
  @Input() dataSource: any[] = []; // Datos de la tabla
  @Input() displayedColumns: string[] = []; // Columnas a mostrar
  @Input() isAnyRowEditing: boolean = false; // Indica si alguna fila está en edición
  @Input() isLoadingRegistrationService: boolean = false; // Estado de carga del servicio de registro
  @Input() isErrorRegistrationService: boolean = false; // Estado de error en el servicio de registro
  @Input() dataConvertClientSelect: any; // Datos de cliente seleccionado
  @Input() dateClients: Array<SelectedClient> = []; // Lista de clientes seleccionados
  @Input() selectedClient: any; // Cliente seleccionado
  @Input() user: any; // Usuario
  @Input() rowErrors: any; // Errores en las filas
  @Input() isRowBeingEdited: boolean = false; // Estado de error en el servicio de registro
  @Input() isAddingRow: boolean = false; // Estado si se está agregando una fila
  @Input() routinesList: Routines | null = null;
  @Input() routinesListLoading: boolean = false;
  @Input() routinesMessageError: boolean = false;
  @Input() selectRoutines?: DetailRoutines;

  @Output() toggleConfirmed = new EventEmitter<any>();
  @Output() changesPending = new EventEmitter<any[]>();
  @Output() editRow = new EventEmitter<any>(); // Emisor para la edición de una fila
  @Output() focusRow = new EventEmitter<any>(); // Emisor para foco de una fila
  @Output() blurRow = new EventEmitter<any>(); // Emisor para pérdida de foco de una fila
  @Output() growRow = new EventEmitter<any>(); // Emisor para el crecimiento de una fila
  @Output() actionRetry = new EventEmitter<any>(); // Emisor para la acción de reintentar
  @Output() addNewRowEvent = new EventEmitter<any>(); // Emisor para nuevos registros

  @ViewChildren('agendaText') agendaTextElements!: QueryList<ElementRef>;
  @ViewChildren('categoryText') categoryTextElements!: QueryList<ElementRef>;
  @ViewChildren('plansText') plansTextElements!: QueryList<ElementRef>;
  @ViewChildren('responsibleText')
  responsibleTextElements!: QueryList<ElementRef>;
  @ViewChildren('supportText') supportTextElements!: QueryList<ElementRef>;
  @ViewChildren('rutineText') supportTextRutina!: QueryList<ElementRef>;
  @ViewChildren('menuTriggerVendedor') menuTriggerVendedor?: QueryList<MatMenuTrigger>;
  @ViewChildren('menuTriggerClient') menuTriggerClient?: QueryList<MatMenuTrigger>;
  @ViewChild('selectClientBinnacle') selectClientBinnacle!: MatSelect; // Referencia al mat-select

  isTruncationCalculated = false; // Flag para evitar cálculos múltiples
  isTruncated: boolean[][] = []; // Estado de truncamiento por fila
  isDateError: boolean = false; // Estado de error de fecha
  private previousState: boolean | null = null; // Estado previo de un elemento
  // Lista para las notas guardadas o editadas
  notesEdit: any[] = [];
  tempData: any[] = [];
  // Lista para las notas eliminadas
  deleteNotesEdit: { id: number }[] = [];
  statusOptions: string[] = [
    'Por iniciar',
    'Con retraso',
    'En proceso',
    'Finalizado',
    'En pausa',
    'Desestimado',
  ]; // Opciones de estado de las reuniones
  newState!: boolean;
  oldState!: boolean;
  selectedEstado!: string; // Estado seleccionado
  activeElement: any = null; // Elemento activo en edición
  newNoteVendedor: string = ''; // Nueva nota del vendedor
  newNoteCliente: string = ''; // Nueva nota del cliente
  isOpen: boolean = false; // Estado si el panel está abierto
  isOpenSatus: boolean = false; // Estado si el panel de estado está abierto
  isOpenRutines: boolean = false; // Rutina si el panel de estado está abierto
  selectedRowId: number | null = null; // ID de la fila seleccionada
  isCloseOtro: boolean = false;
  textOTRO: string = '';
  constructor(
    private datePipe: DatePipe,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    // Registro de iconos SVG
    this.matIconRegistry.addSvgIcon(
      `datepicker_icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/date-picker-icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `datepicker_icon_disabled`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/date-picker-icon_disabled.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `icon_eye_green`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/icon_eye_green.svg'
      )
    );
    this.tempData = JSON.parse(JSON.stringify(this.dataSource));

    // Inicializamos el cliente seleccionado
    this.changeValue(this.dateClients[0]?.code);
  }

  ngAfterViewChecked(): void {
    // Aseguramos que Angular haya procesado el DOM
    if (!this.isTruncationCalculated || this.isAddingRow) {
      this.cdr.detectChanges(); // Forzamos la detección de cambios una sola vez
      this.checkTextTruncation(); // Verificamos el truncamiento del texto
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && changes['dataSource'].currentValue) {
      // Actualizar tempData cuando dataSource cambia (por ejemplo, al cancelar)
      this.tempData = JSON.parse(JSON.stringify(this.dataSource));
    }

    if (changes['isAddingRow'] && !changes['isAddingRow'].firstChange) {
      this.cdr.detectChanges();
      this.isTruncationCalculated = false;
    }
  }

  checkTextTruncation(): void {
    if (!this.isTruncationCalculated || this.isAddingRow) {
      this.cdr.detectChanges(); // Forzamos la detección de cambios una sola vez

      // Grupos de elementos que deben ser revisados para truncamiento
      const elementsGroups = [
        this.agendaTextElements,
        this.categoryTextElements,
        this.plansTextElements,
        this.responsibleTextElements,
        this.supportTextElements,
        this.supportTextRutina,
      ];

      // Iteramos sobre los grupos de elementos y calculamos el truncamiento
      elementsGroups.forEach((elements, groupIndex) => {
        if (elements && elements.length > 0) {
          // Inicializamos el array en isTruncated si no está inicializado
          if (!this.isTruncated[groupIndex]) {
            this.isTruncated[groupIndex] = [];
          }
          elements.toArray().forEach((element, index) => {
            const el = element.nativeElement;

            // Verificamos si el contenido está truncado
            const isTruncated = el.scrollHeight > el.offsetHeight;
            if (this.isAddingRow) {
              this.isTruncated[groupIndex][index + 1] = isTruncated;
            } else {
              this.isTruncated[groupIndex][index] = isTruncated;
            }
          });
        }
      });

      // Marcamos que los cálculos han sido realizados
      this.isTruncationCalculated = true;

      // Forzamos la detección de cambios para actualizar la vista
      this.cdr.detectChanges();
    }
  }

  // Emitir evento de cambio en los datos
  onDataChange(element: any, nameInput: string) {
    const cliente = element["Cliente"];

    // Usar un switch para manejar las diferentes opciones
    switch (cliente) {
      case 'ninguno':
        element["type"] = 'ninguno';
        element["ClienteOTRO"] = null;
        break;
      case 'otros':
        element["type"] = 'otros';
        break;
      default:
        element["type"] = 'cliente';
        element["ClienteOTRO"] = null;
        break;
    }

    // Emitir el evento con los cambios
    this.addNewRowEvent.emit({ element, nameInput });
  }


  // Emitir evento de edición de una fila
  onEdit(row: any) {
    this.editRow.emit(row);
  }

  // Emitir evento de foco en un área de texto
  onFocus(textarea: HTMLTextAreaElement) {
    this.focusRow.emit(textarea);
  }

  // Emitir evento de pérdida de foco en un área de texto
  onBlur(textarea: HTMLTextAreaElement) {
    this.blurRow.emit(textarea);
  }

  // Emitir evento de crecimiento de un área de texto
  autoGrow(value: any) {
    this.growRow.emit(value);
  }

  // Manejo de apertura y cierre del panel de selección
  onSelectPanelOpened() {
    this.isOpen = true;
    this.isCloseOtro = true;
  }
  onSelectPanelOpenedStatus() {
    this.isOpenSatus = true;
  }

  onSelectPanelClosedStatus() {
    this.isOpenSatus = false;
  }
  onSelectPanelOpenedRutines() {
    this.isOpenRutines = true;
  }

  onSelectPanelClosedRutines() {
    this.isOpenRutines = false;
  }
  // Retorna la clase CSS asociada al estado
  getEstadoClass(estado: string): string {
    switch (estado) {
      case 'Por iniciar':
        return 'status-to-start';
      case 'Con retraso':
        return 'status-delayed';
      case 'En proceso':
        return 'status-in-progress';
      case 'Finalizado':
        return 'status-completed';
      case 'En pausa':
        return 'status-paused';
      case 'Desestimado':
        return 'status-dismissed';
      default:
        return 'status-dismissed'; // En caso de valor inesperado
    }
  }

  // Método para formatear las fechas en el formato dd MMM
  formatDateRange(dateRange: { start: Date; end: Date }): string {
    const start = this.datePipe.transform(dateRange.start, 'dd MMM');
    const end = this.datePipe.transform(dateRange.end, 'dd MMM');
    return `${start} - ${end}`;
  }

  // Cambiar valor del cliente seleccionado
  changeValue(clientSelect) {
    this.dataConvertClientSelect = clientSelect?.code;
  }

  // Método para manejar la activación y edición de un elemento
  setActiveElement(element: any, type: 'seller' | 'client') {

    this.activeElement = element;

    if (type === 'seller' && !this.activeElement.notasVendedor) {
      // this.menuTriggerVendedor?.openMenu();
      this.activeElement.notasVendedor = [];
    }
    if (type === 'client' && !this.activeElement.notasCliente) {
      // this.menuTriggerClient?.openMenu();
      this.activeElement.notasCliente = [];
    }
  }

  // Guardar notas
  saveNotes(tipo: string, textoNota: string) {
    if (!textoNota?.trim()) return;

    const noteNew = {
      type: tipo === 'vendedor' ? 'S' : 'C',
      note: textoNota.trim(),
    };

    if (tipo === 'vendedor') {
      this.activeElement['Notas del vendedor']?.unshift(noteNew);
      this.newNoteVendedor = ''; // Limpiar campo de nueva nota
    } else if (tipo === 'cliente') {
      this.activeElement['Notas del cliente']?.unshift(noteNew);
      this.newNoteCliente = ''; // Limpiar campo de nueva nota
    }
  }

  // Eliminar nota
  deleteNote(tipo: string, index: number) {
    if (!this.activeElement) return;

    const notes =
      tipo === 'vendedor' ? 'Notas del vendedor' : 'Notas del cliente';
    if (Array.isArray(this.activeElement[notes])) {
      this.activeElement[notes].splice(index, 1);
    }
  }

  saveNotesEdit(tipo: string, textoNota: string) {
    if (!textoNota?.trim()) return;

    const noteNew = {
      type: tipo === 'vendedor' ? 'S' : 'C',
      note: textoNota.trim(),
      isNew: true, // Etiqueta para identificar notas nuevas
    };

    if (tipo === 'vendedor') {
      this.activeElement['Notas del vendedor']?.unshift(noteNew);
      this.newNoteVendedor = '';
    } else if (tipo === 'cliente') {
      this.activeElement['Notas del cliente']?.unshift(noteNew);
      this.newNoteVendedor = '';
    }
  }

  deleteNoteEdit(tipo: string, index: number, id: number) {
    if (!this.activeElement) return;
    const notes =
      tipo === 'vendedor' ? 'Notas del vendedor' : 'Notas del cliente';
    if (Array.isArray(this.activeElement[notes])) {
      this.activeElement[notes].splice(index, 1);
    }
    if (id) {
      const noteDelete = {
        type: tipo === 'vendedor' ? 'S' : 'C',
        id: id,
        isDelete: true, // Etiqueta para identificar notas nuevas
      };

      this.activeElement['Notas del vendedor']?.unshift(noteDelete);
    }
  }

  // Cancelar la nota actual
  cancelNote(tipo: string) {
    if (tipo === 'vendedor') {
      this.newNoteVendedor = ''; // Limpiar nota del vendedor
      // Cerrar el menú del vendedor
      this.menuTriggerVendedor?.toArray().forEach((item: MatMenuTrigger) => { item.closeMenu() });
    } else if (tipo === 'cliente') {
      this.newNoteCliente = ''; // Limpiar nota del cliente
      this.menuTriggerClient?.toArray().forEach((item: MatMenuTrigger) => { item.closeMenu() });
    }
  }

  // Verificar si hay alguna fila en edición o nueva
  verifyAndUpdateRow() {
    return (
      this.dataSource.some((row) => row.isEditing) ||
      this.dataSource.some((row) => row.isNewRegister)
    );
  }

  // Cambiar estado del toggle
  onToggleChange(event: any, element: any, menuTrigger: MatMenuTrigger) {
    this.newState = event.checked;
    this.oldState = element['Visibilidad cliente'];
    if (element['Visibilidad cliente'] && element.isEditing) {
      event.source.checked = true; // Mantener el estado original
      return;
    }

    if (!element['Visibilidad cliente'] && this.newState) {
      this.previousState = element['Visibilidad cliente'];
      menuTrigger.openMenu();
    } else {
      element['Visibilidad cliente'] = this.newState;
    }

    if(!this.newState){
      this.confirmToggleChange()
    }
  }

  // Confirmar el cambio de estado del toggle
  confirmToggleChange() {
    const data = {
      oldState: this.oldState,
      newState: this.newState,
    };
    this.toggleConfirmed.emit(data); // Emitir el elemento confirmado
  }

  restoreDataSource() {
    // Copiar los datos originales desde tempData
    const restoredData = JSON.parse(JSON.stringify(this.tempData));

    // Limpiar el array existente y agregar los elementos restaurados
    this.dataSource.length = 0;
    this.dataSource.push(...restoredData);

    // Actualizar los estados asociados
    this.dataSource.forEach((row) => (row.isEditing = false));

    // Forzar detección de cambios
    this.cdr.detectChanges();
  }

  // Emite un evento de acción de reintento
  actionCallback(row) {
    this.actionRetry.emit(row);
  }

  showSellerNotes(element: any): boolean {
    return (
      !this.isLoadingRegistrationService &&
      this.user.type === 'Vendedor' &&
      element['Notas del vendedor']?.length > 0
    );
  }

  canEditSellerNotes(element: any): boolean {
    return (
      (element.isEditing || element.isNewRegister) &&
      !this.isErrorRegistrationService
    );
  }

  canShowReadModeNotes(element: any): boolean {
    return (
      (element.isEditing || element.isNewRegister) &&
      !this.isLoadingRegistrationService &&
      !this.isErrorRegistrationService
    );
  }

  canShowDisabledNotes(element: any): boolean {
    return (
      (this.isLoadingRegistrationService || this.isErrorRegistrationService) &&
      (element['Notas del vendedor']?.length > 0 ||
        element.isEditing ||
        element.isNewRegister)
    );
  }
  confirmEdit(element: any): void {
    element.confirmedEditing = true;
  }

  onDateInputClick(element: any, menuTrigger: MatMenuTrigger): void {
    if (!element.confirmedEditing) {
      menuTrigger.openMenu();
    }
  }

  preventDefault(event: Event): void {
    event.stopPropagation(); // Evita que el calendario se despliegue automáticamente
    event.preventDefault(); // Previene el comportamiento por defecto
  }

  openDatepicker(datepicker: MatDatepicker<any>): void {
    datepicker.open(); // Abre el calendario manualmente
  }

  onDateToggleClick(
    element: any,
    menuTrigger: MatMenuTrigger,
    event: Event
  ): void {
    if (!element.confirmedEditing) {
      event.preventDefault();
      event.stopPropagation();
      menuTrigger.openMenu();
    }
  }
  addNewClient(element: any): void {
    this.isCloseOtro = false;
    if (element['ClienteOTRO'] && element['ClienteOTRO'].trim().length > 0) {
      this.selectClientBinnacle.close();
    }


  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    const target = event.target as HTMLElement;

    // Verificar si el mat-select está definido y si el valor es 'otros'
    if (
      this.selectClientBinnacle &&
      this.selectClientBinnacle.value === 'otros' && this.isCloseOtro
    ) {
      this.isCloseOtro = false;
      this.selectClientBinnacle.close(); // Cerrar el mat-select
    }
  }

  // Método para mantener abierto el selector cuando se selecciona 'otros'
  keepSelectOpen(selectClientBinnacle: MatSelect): void {
    if (selectClientBinnacle && selectClientBinnacle?.value === 'otros' && this.isCloseOtro) {
      this.isOpen = true; // Establecer que el selector está abierto

      this.selectClientBinnacle.open(); // Abrir el selector manualmente
    } else {
      this.isOpen = false;
    }
  }


}

