import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import * as authActions from '@core/store/auth/auth.actions';
import * as authClientListActions from '@core/store/auth/auth-list-client.actions';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Sectors } from '@core/interfaces/user.model';
import { Md5 } from 'ts-md5';
import { sha256 } from 'js-sha256';
import { Login, LoginClient } from '@core/interfaces/login';
import { GtmEventService } from '@core/analytics/gtm-events.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm!: FormGroup;
  public hidePaswordLogin = true;
  private logInSuscription!: Subscription;
  private logInSuscriptionClient!: Subscription;
  public logInError = false;
  public loginInProcess = false;
  // En el archivo del componente
  isInputFocused: boolean = false;
  businessUnities: Array<Sectors> = [];
  constructor(
    private store: Store<AppState>,
    private _formBLogin: FormBuilder,
    private router: Router,
    private _localStorageService: LocalStorageService,
    private _gtmEventService: GtmEventService
  ) {
    this.cleanStorage();
  }

  ngOnInit(): void {
    this.initForm();
    this.initStore();
    /* window.dataLayer.push({
      event: 'pagina-vista',
      // 'category': '',
      // 'action': '',
      label: this.router.url,
    }); */
  }

  initForm() {
    this.loginForm = this._formBLogin.group({
      // username: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      username: [
        '',
        [Validators.required, this.validateInput, Validators.maxLength(50)],
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(25),
        ]),
      ],
    });
  }
  validateInput(control) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const numberPattern = /^[0-9]+$/;

    if (emailPattern.test(control.value)) {
      return null; // Valid email
    } else if (numberPattern.test(control.value)) {
      // Check the length of the number
      if (control.value.length !== 10) {
        return { invalidNumberLength: true }; // Invalid number length
      }

      return null; // Valid number with a length of 10
    } else {
      return { invalidInput: true }; // Neither email nor number
    }
  }

  initStore() {
    this.logInSuscription = this.store
      .select('auth')
      .subscribe((authState: any) => {
        if (authState.user?.type === 'Cliente') {
          if (!authState.isLoading) {
            this.loginInProcess = false;
          }
          if (authState.user !== null) {
            if (authState.user.sectors.length === 1) {
              this.initStoreSectors(authState.user.sectors);
              this.router.navigate(['/tienda']);
            } else {
              this.router.navigate(['/unidades-de-negocio']);
            }
          }
        }
        this.logInError = authState.errorLogin;
        if(this.logInError ){
          this.loginInProcess = false;
        }
      });
    this.logInSuscriptionClient = this.store
      .select('authListClient')
      .subscribe((authState: any) => {
        if (authState.user?.type === 'Vendedor') {
          if (!authState.isLoading) {
            this.loginInProcess = false;
          }
          if (authState.user !== null) {
            this.router.navigate(['/seleccionar-cliente']);
          }
        }

        this.logInError = authState.errorLogin;
        if (authState.errorLogin) {
          if (!authState.isLoading) {
            this.loginInProcess = false;
          }
        }
      });
  }

  typeChangePassword() {
    this.hidePaswordLogin = !this.hidePaswordLogin;
  }

  onEnterSignIn() {
    if (this.loginForm.valid) {
      this.loginInProcess = true;
      const temPasword = this.loginForm.value.password.trim();
      const inputValue = this.loginForm.value.username.trim();
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValue)) {
        const password = sha256.update(temPasword).hex();
        const data: LoginClient = {
          email: this.loginForm.value.username,
          password: password,
          type: 'Vendedor',
        };
        this.store.dispatch(authClientListActions.logInListClient({ data }));
      } else {
        const md5 = new Md5();
        const password = md5.appendStr(temPasword).end();
        let data = {
          username: this.loginForm.value.username,
          password: password,
          type: 'Cliente',
        };
        this.store.dispatch(authActions.logIn({ data }));
      }
    }
  }

  ngOnDestroy(): void {
    this.logInSuscription.unsubscribe();
    this.unsubscribeClientFromStore();
  }
  initStoreSectors(sectors) {
    this.businessUnities = sectors.map((element: Sectors) => {
      let temp: Sectors = {
        ...element,
        hover: false,
        image: element.name.toLowerCase(),
      };
      return temp;
    });
    this._localStorageService.setItem('sectorSelect', this.businessUnities[0]);
    this._localStorageService.setItem('sectors', this.businessUnities);
  }
  unsubscribeClientFromStore() {
    if (this.logInSuscriptionClient) {
      this.logInSuscriptionClient.unsubscribe();
    }
  }
  onUsernameInputKeydown(event: KeyboardEvent) {
    const usernameControl = this.loginForm.get('username');
    if (usernameControl) {
      const inputValue = usernameControl.value;
      if (event.key === ' ' && inputValue.trim() === '') {
        event.preventDefault(); // Evitar la entrada de espacios al inicio
      } else if (
        event.key !== ' ' &&
        inputValue.trim() === '' &&
        inputValue.startsWith(' ')
      ) {
        // Eliminar espacio en blanco inicial cuando se ingrese el primer carácter
        usernameControl.setValue(inputValue.trim());
      }
    }
  }

  onPasswordInputKeydown(event: KeyboardEvent) {
    const passwordControl = this.loginForm.get('password');
    if (passwordControl) {
      const inputValue = passwordControl.value;
      if (event.key === ' ' && inputValue.trim() === '') {
        event.preventDefault(); // Evitar la entrada de espacios al inicio
      } else if (
        event.key !== ' ' &&
        inputValue.trim() === '' &&
        inputValue.startsWith(' ')
      ) {
        // Eliminar espacio en blanco inicial cuando se ingrese el primer carácter
        passwordControl.setValue(inputValue.trim());
      }
    }
  }

  cleanStorage() {
    this._localStorageService.removeItem('sectors');
    this._localStorageService.removeItem('sectorSelect');
  }
}
