import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageActivitiesNull } from 'src/assets/data/errors-message';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AccessFormServiceService } from '@core/services/form/access-form-service.service';

@Component({
  selector: 'app-routine-modal',
  templateUrl: './routine-modal.component.html',
  styleUrls: ['./routine-modal.component.scss'],
})
export class RoutineModalComponent {
  public pageSize = 1; // Ítems por página
  messageClientNull = [MessageActivitiesNull];
  constructor(
    public dialogRef: MatDialogRef<RoutineModalComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private accessService: AccessFormServiceService
  ) {}
  onPageChange(page: number) {
    this.data.routine = this.findRoutineByPosition(page + 1);
  }

  close(): void {
    this.dialogRef.close();
  }

  goToUrl(url: string) {
    window.open(url, '_blank');
  }


  goToUrlOpen(value, routine) {
    // Verificar que todos los campos necesarios existan y no estén vacíos
    if (value?.idForm && value.idForm !== '' && value?.id && routine?.id) {
      // Crear el objeto con los tres parámetros para acceso
      const accessParams = {
        idForm: value.idForm,
        idActivity: value.id,
        idRoutine: routine.id,
      };
  
      // Permitir acceso y almacenar los parámetros
      this.accessService.allowAccess(accessParams);
  
      // Crear la URL con todos los parámetros como segmentos de la ruta
      const url = this.router.createUrlTree([
        '/formulario',
        value.idForm,
        value.id,
        routine.id
      ]).toString();
  
      window.open(url, '_blank');
    } else {
      // Si alguno de los parámetros falta, abrir `formatLink` si está disponible
      window.open(value?.formatLink, '_blank');
    }
  }  
  
  findRoutineByPosition(position: number) {
    return this.data.routines.routines.find(
      (routine: any) => routine.position === position
    );
  }

  processText(text: string): SafeHtml {
    const html = text.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
