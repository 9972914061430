import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@core/interfaces/user.model';
import { AccessFormServiceService } from '@core/services/form/access-form-service.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SelectedClientService } from '@core/services/selected-client/selected-client.service';
import { take } from 'rxjs';
import { SearchNullMessage } from 'src/assets/data/errors-message';
import { firstValueFrom } from 'rxjs';
import { ControlFormsService } from '@core/services/control-forms/control-forms.service';

// Interfaz que define la estructura de una pregunta
export interface Question {
  id: number;
  type: string; // Tipos de preguntas: 'datepicker', 'textArea', etc.
  descriptionGeneral?: string;
  options?: string[]; // Opciones si son necesarias
  titleQuestion?: string;
  description?: string;
  isRequired?: boolean;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  typeQuestions: Question[] = [
    { id: 1, type: 'datepicker' },
    { id: 2, type: 'textArea' },
    { id: 3, type: 'radioButton' },
    { id: 4, type: 'checklist' },
    { id: 5, type: 'optionalTextArea' },
    { id: 6, type: 'uploadFile' },
    { id: 7, type: 'combobox' },
    { id: 8, type: 'listView' },
  ];

  // Almacenamiento de datos del formulario y preguntas cargadas
  formData: { [key: number]: any } = {};
  questions: Question[] = [];
  searchTextRadioButton: string = ''; // Texto de búsqueda
  searchTextCheckbox: string = ''; // Texto de búsqueda
  searchTextSelect: string = ''; // Texto de búsqueda
  @ViewChild('matSelect') matSelect!: MatSelect; // Referencia al mat-select de Angular Material
  @ViewChild('fileInput') fileInput!: ElementRef;
  user!: User;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn']; // Temporadas para selección
  currentQuestionIndex: number = 0; // Índice de la pregunta actual
  sectionNumber: number = 1; // Número de la sección actual
  filteredQuestions: Question[] = []; // Preguntas filtradas
  filteredOptions: { [key: number]: string[] } = {}; // Opciones filtradas por pregunta
  filteredOptionsCheckbox: { [key: number]: string[] } = {}; // Opciones filtradas por pregunta
  filteredSelect: { [key: number]: string[] } = {}; // Opciones filtradas por pregunta
  canAccessForm: boolean = false; // Inicialización
  isOpen: boolean = false;
  labelVisible: boolean = true; // Controla la visibilidad de la etiqueta
  idForm!: string;
  idActivity!: string;
  idRoutine!: string;
  data: any[] = [];
  formLoading: boolean = true;
  isSubmitting: boolean = false;
  searchMessageFormGeneralNull = [SearchNullMessage];
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private selectedClientService: SelectedClientService,
    private route: ActivatedRoute,
    private router: Router,
    private accessService: AccessFormServiceService,
    private _localStorageService: LocalStorageService,
    private accessControlService: ControlFormsService
  ) {
    // Registro de íconos SVG
    this.matIconRegistry.addSvgIcon(
      `datepicker_icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/date-picker-icon.svg'
      )
    );
    // Subscripción al evento de reintento
    this.accessControlService.retry$.subscribe(() => {
      this.onSubmit(); // Ejecuta el envío al recibir una solicitud de reintento
    });
  }

  ngOnInit() {
    this.user = this._localStorageService.getItem('user');
    this.idForm = this.route.snapshot.paramMap.get('idForm') || '';
    this.idActivity = this.route.snapshot.paramMap.get('idActivity') || '';
    this.idRoutine = this.route.snapshot.paramMap.get('idRoutine') || '';
    this.updateSectionNumber(); // Actualiza el número de sección inicial
    this.loadSimulatedData(); // Carga los datos simulados
  }

  // Método para cargar los datos simulados
  loadSimulatedData() {
    this.formLoading = true; // Inicia el estado de carga

    // Verificar acceso
    if (
      !this.accessService.isAccessAllowed() ||
      this.idForm !== this.accessService.getAllowedFormId() ||
      this.idActivity !== this.accessService.getAllowedActivityId() ||
      this.idRoutine !== this.accessService.getAllowedRoutineId()
    ) {
      this.accessControlService.allowAccess();
      this.router.navigate(['/formulario/error']); // Redirigir a error si no hay acceso
    } else {
      if (this.idForm) {
        this.selectedClientService
          .getformId(this.idForm)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              this.data = res;
              this.formLoading = false; // Finaliza el estado de carga en caso de éxito
              // Convierte answer en options para cada item
              this.data = this.data.map((item: any) => {
                if (item.answer) {
                  item.options = item.answer
                    .split(';')
                    .map((option) => option.trim());
                }
                return item;
              });
              // Mapea los datos simulados a preguntas utilizando typeQuestions
              this.questions = this.data
                .map((item) => {
                  const tipoPregunta = this.typeQuestions.find(
                    (q) => q.id === item.designId
                  );
                  if (!tipoPregunta) {
                    console.error(
                      `No se encontró el tipo de pregunta para el ID: ${item.idForm}`
                    );
                    return null; // Si no se encuentra, retorna null
                  }

                  return {
                    id: item.idQuestion,
                    type: tipoPregunta.type,
                    descriptionGeneral: item.formName || '',
                    titleQuestion: item.question || '',
                    description: item.description || '',
                    options: item.options || [],
                    isRequired: item.isRequired || false,
                  };
                })
                .filter(Boolean) as Question[]; // Filtra valores null y asegura el tipo

              // Asegúrate de que 'options' sea la lista completa de opciones de la pregunta actual
              this.filterOptions(
                this.questions[this.currentQuestionIndex]?.options || []
              );
              this.filterOptionsCheckbox(
                this.questions[this.currentQuestionIndex]?.options || []
              );
              this.filterOptionsSelect(
                this.questions[this.currentQuestionIndex]?.options || []
              );
            },
            (error) => {
              this.formLoading = false; // Finaliza el estado de carga en caso de error
              if (error.status === 404) {
                // console.log('Error 404: No se encontró el recurso solicitado');
              } else if (error.status === 500) {
                // console.log('Error 500: Error interno del servidor');
              }
            }
          );
      }
    }
  }

  isNextButtonDisabled(): boolean {
    const currentQuestion = this.questions[this.currentQuestionIndex];

    if (!currentQuestion.isRequired) {
      // Si hay algún archivo mayor a 5MB, deshabilitar el botón
      const answer = this.formData[currentQuestion.id];
      if (
        currentQuestion.type === 'uploadFile' &&
        answer?.some((file) => file.size > 5 * 1024 * 1024)
      ) {
        return true;
      }
      return false; // Si no es obligatorio y no hay problemas de tamaño, habilita el botón
    }

    const answer = this.formData[currentQuestion.id];

    switch (currentQuestion.type) {
      case 'datepicker':
        if (!answer) {
          this.formData[currentQuestion.id] = new Date();
          return false;
        }
        return false;

      case 'textArea':
        return !answer || answer.length < 2 || answer.length > 5000;

      case 'radioButton':
        return !answer;

      case 'checklist':
        return (
          !answer ||
          !Object.values(answer.selectedOptions || {}).some(
            (val) => val === true
          )
        );

      case 'combobox':
        return !answer;

      case 'listView':
        return !answer || !(answer >= 1 && answer <= 5);

      case 'optionalTextArea':
        if (answer && answer.selectedOptions) {
          const hasChecked = Object.values(answer.selectedOptions).some(
            (val) => val === true
          );
          const allTextValid = Object.entries(answer.selectedOptions)
            .filter(([_, isSelected]) => isSelected)
            .every(
              ([option]) =>
                answer.descriptions[option] &&
                answer.descriptions[option].length >= 2
            );
          return !(hasChecked && allTextValid);
        }
        return true;

      case 'uploadFile':
        // Si es obligatorio, requiere al menos un archivo
        const hasFile = answer && answer.length > 0;
        const hasOversizedFile = answer?.some(
          (file) => file.size > 5 * 1024 * 1024
        );
        return !hasFile || hasOversizedFile;

      default:
        return false;
    }
  }

  nextQuestion() {
    // Evita avanzar si el botón "Siguiente" debe estar deshabilitado
    if (this.isNextButtonDisabled()) return;

    // Avanza a la siguiente pregunta
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
      this.updateSectionNumber(); // Actualiza el número de sección
      this.filterOptions(
        this.questions[this.currentQuestionIndex]?.options || []
      ); // Actualiza las opciones filtradas
      this.filterOptionsCheckbox(
        this.questions[this.currentQuestionIndex]?.options || []
      );
      this.filterOptionsSelect(
        this.questions[this.currentQuestionIndex]?.options || []
      );
    }
  }

  previousQuestion() {
    // Retrocede a la pregunta anterior
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.updateSectionNumber(); // Actualiza el número de sección
      this.filterOptions(
        this.questions[this.currentQuestionIndex]?.options || []
      ); // Actualiza las opciones filtradas
    }
  }

  updateSectionNumber() {
    // Actualiza el número de sección basado en el índice actual
    this.sectionNumber = this.currentQuestionIndex + 1;
  }

  get totalSections(): number {
    return this.questions.length; // Total de preguntas como el total de secciones
  }

  onFileChange(event: any, questionId: number) {
    // Maneja el cambio de archivo en una pregunta
    const file = event.target.files[0];
    this.formData[questionId] = file; // Guarda el archivo en formData
  }

  formatSectionNumber(section: number): string {
    // Formatea el número de sección para que tenga dos dígitos
    return section < 10 ? '0' + section : '' + section;
  }

  formatTotalSections(total: number): string {
    // Formatea el total de secciones para que tenga dos dígitos
    return total < 10 ? '0' + total : '' + total;
  }

  async onSubmit() {
    this.isSubmitting = true; // Inicia el spinner

    const fileQuestions = Object.keys(this.formData).filter((key) => {
        return (
            Array.isArray(this.formData[key]) &&
            this.formData[key].some((item) => item.file)
        );
    });

    const fileData = fileQuestions
        .map((questionKey) => {
            return this.formData[questionKey].map((fileItem) => ({
                fileName: fileItem.file.name,
                code: this.user.code,
                idForm: this.idForm,
                idQuestion: questionKey,
            }));
        })
        .flat();

    try {
        const preSignedUrls = await firstValueFrom(
            this.selectedClientService.createPresigned(fileData)
        );

        const uploadedFiles: { fileName: string; pathName: string }[] = [];

        for (let i = 0; i < preSignedUrls.length; i++) {
            const { preSignedUrl, filePath } = preSignedUrls[i];
            const questionKey = fileData[i].idQuestion;
            const file = this.formData[questionKey].find(
                (f) => f.file.name === fileData[i].fileName
            ).file;

            const response = await firstValueFrom(
                this.selectedClientService.uploadFileToBlob(preSignedUrl, file)
            );

            if (response?.status === 200 || response?.status === 201) {
                uploadedFiles.push({
                    fileName: file.name,
                    pathName: filePath,
                });
            } else {
                throw new Error(`Error al subir el archivo ${file.name}`);
            }
        }

        const answerData = this.questions.map((question) => {
            const questionData = this.formData[question.id];
            let answer: string = '';

            if (fileQuestions.includes(question.id.toString())) {
                answer = '';
            } else if (typeof questionData === 'number') {
                answer = questionData.toString();
            } else if (typeof questionData === 'string') {
                answer = questionData;
            } else if (questionData instanceof Date) {
                const day = questionData.getDate().toString().padStart(2, '0');
                const month = (questionData.getMonth() + 1)
                    .toString()
                    .padStart(2, '0');
                const year = questionData.getFullYear().toString();
                answer = `${day}/${month}/${year}`;
            } else if (
                typeof questionData === 'object' &&
                questionData.selectedOptions
            ) {
                answer = Object.entries(questionData.selectedOptions)
                    .filter(([_, isSelected]) => isSelected)
                    .map(([option]) => {
                        const description = questionData.descriptions[option];
                        return description ? `${option}:${description}` : option;
                    })
                    .join(';');
            } else {
                answer = ''; // Si no hay respuesta, asigna un string vacío
            }

            const questionFiles = uploadedFiles.filter(
              (file) =>
                Array.isArray(this.formData[question.id]) &&
                this.formData[question.id].some((f: any) => f.file.name === file.fileName)
            );            

            return {
                code: this.user.code,
                idRoutine: this.idRoutine,
                idActivity: this.idActivity,
                idForm: this.idForm,
                answer: answer,
                idQuestion: question.id.toString(),
                files: questionFiles.length > 0 ? questionFiles : [],
            };
        });

        await firstValueFrom(this.selectedClientService.insertAnswer(answerData));
        this.accessControlService.allowAccess();
        this.isSubmitting = false; // Finaliza el spinner
        this.router.navigate(['/formulario/exito']);
    } catch (error) {
        this.accessControlService.allowAccess();
        this.isSubmitting = false; // Finaliza el spinner
        this.router.navigate(['/formulario/algo-salio-mal']);
    }
  }

  onCheckboxChange(option: string) {
    const currentQuestionId = this.questions[this.currentQuestionIndex].id;

    // Inicializa formData para la pregunta actual si no existe
    if (!this.formData[currentQuestionId]) {
      this.formData[currentQuestionId] = {
        selectedOptions: {},
        descriptions: {},
      };
    }

    // Marca o desmarca la opción
    this.formData[currentQuestionId].selectedOptions[option] =
      !this.formData[currentQuestionId].selectedOptions[option];

    // Si se selecciona, inicializa la descripción
    if (this.formData[currentQuestionId].selectedOptions[option]) {
      this.formData[currentQuestionId].descriptions[option] = ''; // Inicializa con un string vacío
    } else {
      delete this.formData[currentQuestionId].descriptions[option]; // Elimina la descripción si se desmarca
    }
  }

  filterOptions(options) {
    const query = this.searchTextRadioButton.toLowerCase();
    // Si no hay texto de búsqueda, mostramos todas las opciones
    if (!query) {
      this.filteredOptions[this.questions[this.currentQuestionIndex].id] =
        options;
    } else {
      // Filtra las opciones basadas en la búsqueda
      this.filteredOptions[this.questions[this.currentQuestionIndex].id] =
        options.filter((option) => option.toLowerCase().includes(query));
    }
  }
  filterOptionsCheckbox(options) {

    const query = this.searchTextCheckbox.toLowerCase();
    // Si no hay texto de búsqueda, mostramos todas las opciones
    if (!query) {
      this.filteredOptionsCheckbox[
        this.questions[this.currentQuestionIndex].id
      ] = options;
    } else {
      // Filtra las opciones basadas en la búsqueda
      this.filteredOptionsCheckbox[
        this.questions[this.currentQuestionIndex].id
      ] = options.filter((option) => option.toLowerCase().includes(query));
    }
  }
  filterOptionsSelect(options) {
    const query = this.searchTextSelect.toLowerCase();
    // Si no hay texto de búsqueda, mostramos todas las opciones
    if (!query) {
      this.filteredSelect[this.questions[this.currentQuestionIndex].id] =
        options;
    } else {
      // Filtra las opciones basadas en la búsqueda
      this.filteredSelect[this.questions[this.currentQuestionIndex].id] =
        options.filter((option) => option.toLowerCase().includes(query));
    }
  }
  deleteTextSearchRadio(options) {
    this.searchTextRadioButton = '';
    this.filteredOptions[this.questions[this.currentQuestionIndex].id] =
      options;
  }
  deleteTextSearch(options) {
    this.searchTextCheckbox = '';
    this.filteredOptionsCheckbox[this.questions[this.currentQuestionIndex].id] =
      options;
  }
  deleteTextSearchCombobox(options) {
    this.searchTextSelect = '';
    this.filteredSelect[this.questions[this.currentQuestionIndex].id] = options;
  }
  selectNumber(num: number) {
    const currentQuestionId = this.questions[this.currentQuestionIndex].id;
    this.formData[currentQuestionId] = num; // Almacena el número seleccionado
  }

  isSelected(num: number): boolean {
    const currentQuestionId = this.questions[this.currentQuestionIndex].id;
    return this.formData[currentQuestionId] === num; // Retorna true si el número está seleccionado
  }

  selectOption() {}
  onSelectPanelOpened() {
    this.isOpen = true;
  }

  onSelectPanelClosed(value) {
    this.isOpen = false;
    if (value) {
      this.labelVisible = false;
    } else {
      this.labelVisible = true;
    }
  }
  // Evento para arrastrar archivos sobre el contenedor
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  // Evento cuando se arrastran y sueltan múltiples archivos
  onFilesDropped(event: DragEvent, questionId: number) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files) {
      Array.from(files).forEach((file) => this.processFile(file, questionId));
    }
  }

  // Evento cuando se seleccionan múltiples archivos
  onFilesSelected(event: Event, questionId: number) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      Array.from(input.files).forEach((file) =>
        this.processFile(file, questionId)
      );
    }

    // Resetea el valor del input para permitir volver a subir el mismo archivo
    this.fileInput.nativeElement.value = '';
  }

  // Simula la carga de archivos con validación de tamaño, límite de cantidad y formato
  processFile(file: File, questionId: number) {
    const maxFileSize = 5 * 1024 * 1024; // Tamaño máximo de archivo en bytes (5 MB)
    const maxFilesCount = 5; // Límite máximo de archivos

    // Validación de formato de archivo permitido
    const allowedFormats = ['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png', '.heic', '.heif', '.webp'];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf('.'))
      .toLowerCase();

    // Si el formato no es permitido, omitir el archivo
    if (!allowedFormats.includes(fileExtension)) {
      return;
    }

    // Si ya se alcanzó el límite de archivos, no permite agregar más
    if (this.formData[questionId]?.length >= maxFilesCount) {
      return;
    }

    // Inicializa el estado del archivo (con isLoaded: false y progress en 0)
    const fileData = {
      file,
      name: file.name,
      size: file.size,
      isLoaded: false, // Indica que está en carga
      progress: 0, // Progreso inicial de carga
      isOversized: file.size > maxFileSize, // Indica si el archivo excede el tamaño permitido
    };

    // Inicializa el array si no existe y agrega el archivo
    if (!this.formData[questionId]) {
      this.formData[questionId] = [];
    }
    this.formData[questionId].push(fileData);

    // Simula el progreso de carga para todos los archivos, sin importar el tamaño
    const interval = setInterval(() => {
      fileData.progress += 10;
      if (fileData.progress >= 100) {
        fileData.isLoaded = true;
        clearInterval(interval);
      }
    }, 200); // Incremento de 10% cada 200ms para simular la carga
  }

  // Elimina el archivo del array de formData y de la interfaz
  removeFile(index: number) {
    const questionId = this.questions[this.currentQuestionIndex]?.id;
    if (questionId !== undefined) {
      this.formData[questionId].splice(index, 1);
    }

    // Resetea el valor del input para evitar problemas de buffer
    this.fileInput.nativeElement.value = '';
  }
  ngOnDestroy() {
    this.accessService.resetAccess(); // Restablecer acceso al destruir el componente
  }
}
