import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-agreements-skeleton',
  templateUrl: './trade-agreements-skeleton.component.html',
  styleUrls: ['./trade-agreements-skeleton.component.scss'],
})
export class TradeAgreementsSkeletonComponent implements OnInit {
  dataSource: number[] = Array(7).fill(0); // Crea un array de 7 elementos
  displayedColumns: string[] = [
    'Fecha de creación',
    'Estado',
    'Agenda',
    'Categoría',
    'Planes de acción / Actividades',
    'Notas del vendedor',
    'Mis notas',
  ];

  constructor() {}

  ngOnInit() {}
}
