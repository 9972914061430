import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sectors, User } from '@core/interfaces/user.model';
import { AuthService } from '@core/services/authentication/auth.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store/app.reducer';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, map, Observable, Subscription, take } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Categories_request, categories_response } from '@core/interfaces/categories';
import { CategoriesService } from '@core/services/categories/categories.service';
import { ProductsService } from '@core/services/products/products.service';
import { CarsState } from '@core/interfaces/cars';
import { ShowCarComponent } from './show-car/show-car.component';
import * as launchersActions from '@core/store/launchers/launchers.actions'
import * as shoppingActions from '@core/store/shoppingCar/shoppingCar.actions'
import * as authActions from '@core/store/auth/auth.actions';
import { UtilsService } from '@core/services/utils/utils.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AccountService } from '@core/services/account/account.service';
import { GtmEventService } from '@core/analytics/gtm-events.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { warn } from 'console';
import { selectLoginUserInfo } from '@core/store/app.selectors';
import { CheckingAccountService } from '@core/services/checking-account/checking-account.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  @ViewChild("search_opacity")
  search_opacity!: ElementRef;
  @ViewChild('drawer')
  sidenav!: MatSidenav;
  @ViewChild('drawerCar') ViewCar!: MatSidenav;
  panelClosingActions!: Observable<MatOptionSelectionChange | null>;
  control = new FormControl();
  businessUnities: Array<Sectors> = [];
  businessUnitiesTrue: Array<Sectors> = [];
  unitSelect!: Categories_request;
  user!: User;
  iconProfileUser: any;
  listCategories: Array<categories_response> = [];
  hidden = false;
  searchResponse$: any;
  searchTextValue = '';
  initialDateSearch = [];
  carsState!: CarsState;
  car!: any;
  badge = 0;
  products: any[] = [];
  dataCurrentAccount: any;
  resAccounts: any;
  private carSubscription!: Subscription;
 typeUser:any;
  @ViewChildren('plot', { read: ElementRef }) el!: QueryList<ElementRef>;
  @ViewChild("appShowCar", { read: ShowCarComponent }) appShowCar!: ShowCarComponent
  @ViewChild(ShowCarComponent) showCarComponent!: ShowCarComponent;
  accountsSuscription$!: Subscription;
  
  constructor(
    private _authService: AuthService,
    private _categoriesService: CategoriesService,
    private _productsService: ProductsService,
    public _localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private router: Router,
    private _accountService: AccountService,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef,
    private _gtmEventService: GtmEventService,
    private _checkingAccountService: CheckingAccountService,
    private _snackBar: MatSnackBar
  ) {
  }
  ngOnDestroy(): void {
    this.carSubscription.unsubscribe;
  }

  ngOnInit() {
    this.loadLocalData();
    this.initStore();
    this.initialData();
    this.getCheckingAccount();
    this.PROFILE_MENU_USER(this._localStorageService.getItem('user').settings);
  }

  loadLocalData() {
    this.businessUnities = this._localStorageService.getItem('sectors');
    this.unitSelect = this._localStorageService.getItem('sectorSelect');
    this.user = this._localStorageService.getItem('user');
    const { sector, zone, schema, segment } = this.unitSelect;
    const data = {
      categoryCode: '002',
      brandCode: null,
      sector,
      zone,
      schema,
      segment,
      clientCode: this.user.code
    }
    if (this.unitSelect.name == 'Soluciones') {
      this._productsService.getProductsForCategory(data).pipe(take(1)).subscribe({
        next:(res) => {
          res?.map((brand) => {
            brand.products.map((product) => {
              this.products.push(product)
            })

          })
          this._localStorageService.setItem('flours', this.products)
        },
        error: (err) => {
          console.error('Error en traer productos de Soluciones');
        },
      })
    }
  }

  initStore() {
    this.store.select('auth').subscribe((authState) => {
      if (authState.user) {
        this.user = authState.user;
      }
    })
    this.carSubscription = this.store.select('carsState').subscribe((carsState) => {
      this.carsState = carsState;
      if (carsState) {
        const tempCar = carsState.cars.find((car) => (this.unitSelect && car.unitBussines == this.unitSelect.name))
        this.car = tempCar ? tempCar : undefined
        this.badge = this.car?.products ? this.car.products.length : 0;
      }
    })
  }

  initialData() {
    if (this.businessUnities) {
      this.businessUnitiesTrue = this.businessUnities?.filter(element => element.name !== this.unitSelect.name);
    }
    if (this.unitSelect) {
      this.getCategoriesList(this.unitSelect);
      this.getLaunches();
    }
  }

  clickMyAccount(){
    this._gtmEventService.clickMyAccountEvent();
  }

  selectOption(value) {
    this.sidenav.closedStart;
    //close car
    this.ViewCar.closedStart;
    this.ViewCar.autoFocus = true;

    this.searchResponse$ = null;
    this.searchTextValue = '';
    this.listCategories = [];
    this.appShowCar.shouldScroll = true;
    this.dataCurrentAccount = null;

    if (this.businessUnitiesTrue) {
      this.businessUnitiesTrue = this.businessUnities.filter(element => element.name !== value.name);
      this.unitSelect = value;
      this._localStorageService.setItem('sectorSelect', this.unitSelect);
      this.selectAccount(value);
      this.businessUnitiesTrue = this.businessUnitiesTrue.map((element: Sectors) => {
        let temp: Sectors = {
          ...element,
          hover: false
        }
        return temp
      });
    }
    if (this.unitSelect) {
      this.getCategoriesList(this.unitSelect);
      this.initStore();
      this.loadLocalData();
      this.getLaunches();
    }
    this.router.navigate(['/tienda'])
  }

  getLaunches(){

    let data = {
      "code": this.user.code,
      "sector":this.unitSelect.sector,
      "zone":this.unitSelect.zone,
      "schema": this.unitSelect.schema,
      "segment": this.unitSelect.segment,
    }
    this._productsService.getLaunches(data).pipe(take(1)).subscribe({
      next: (res) => {
        this.store.dispatch(launchersActions.setLaunchers({launchers: res ? res : []}))

      },
      error: () => {},
      complete: () => {}
    }
    )
  }
  getCategoriesList(data) {
    data.code = this.user.code;
    this._categoriesService.getCategories(data).pipe(take(1))
      .subscribe((res: any) => {
        this.listCategories = res;
      });
  }

  selectIconProfile(optionIcon) {

    switch (optionIcon) {
      case 'Avance de Ventas':
        this._gtmEventService.clickAdvanceSalesEvent();
        this.router.navigate(['/cuenta/avances-ventas']);
        break;
      case 'Reconocimientos':
        this._gtmEventService.clickAwardsEvent();
        this.router.navigate(['/cuenta/premios']);
        break;
      case 'Historial de compras':
        this._gtmEventService.clickPurchaseHistoryEvent();
        this.router.navigate(['/cuenta/historial']);
        break;
      case 'Cuenta corriente':
        this._gtmEventService.clickCheckingAccountEvent();
        this.router.navigate(['/cuenta/facturacion']);
        break;
      case 'Comprobante electrónico':
        this._gtmEventService.clickElectronicReceiptEvent();
        window.open('http://asp401r.paperless.com.pe/PortalAlicorp/', "_blank");
        break;
      case 'Aliados':
        this._gtmEventService.clickAlliesEvent();
        this.router.navigate(['/cuenta/aliados']);
        break;
      case 'Acuerdos comerciales':
        this.router.navigate(['/cuenta/acuerdos-comerciales']);
        break;
      case 'Cambiar contraseña':
        this._gtmEventService.clickPasswordChangeEvent();
        this.router.navigate(['/cuenta/perfil/credenciales']);
        break;
      case 'Cerrar sesión':
        this.logout();
        break;
      default:
        break;
    }
  }

  clickSearchBar(){
    this._gtmEventService.clickSearchBarEvent();
  }

  clickUploadOrder(){
    this._gtmEventService.clickUploadOrderEvent();
  }

  logout() {
    this.store.dispatch(authActions.unsetUser())
    this.store.dispatch(shoppingActions.unsetCar())
    this._authService.logout()
  }

  openCategories() {
    this.sidenav.toggle();
    this._gtmEventService.clickCategoriesEvent();
  }

  categoriesEmit(a) {
    this.sidenav.toggle();
    if (a.type == 'category') {
      this.router.navigate(['/tienda/busqueda'], { queryParams: { categoryCode: a.category.categoryCode, categoryName: a.category.categoryName } });
    } else {
      this.router.navigate(['/tienda/busqueda'], { queryParams: { categoryCode: a.category.categoryCode, categoryName: a.category.categoryName, brandCode: a.brandCode } });
    }
  }

  getList(text) {
    let data = this.unitSelect;
    data.code = this.user.code;
    data.description = text;
    if (text && text.length >= 3 && text.length <= 50) {

      this.searchResponse$ = this._categoriesService.getSearchCategories(data).pipe(take(1),
        // debounceTime(500),
        map((res) => {
          this.saveInitialDate(res);
          if (res && res.length > 0) {
            this.addOpacityAutocomplete();
          } else {
            this.removeOpacityAutocomplete();
          }
          return res;
        })
      );
    } else {
      this.removeOpacityAutocomplete();
      this.searchResponse$ = null;
    }
  }

  mouseUp(event) {
    //vaciar data
    this.removeOpacityAutocomplete();
    if (event && event.length < 1) {
      this.searchResponse$ = null;
    }
  }

  saveInitialDate(response) {
    this.initialDateSearch = response;
  }

  mouseClickInput(text) {
    if (text && this.initialDateSearch && text.length >= 3 && this.initialDateSearch.length > 0) {
      this.addOpacityAutocomplete();
    }
    else {
      this.removeOpacityAutocomplete();
    }
  }

  searchEngineSelectedProduct(value, description) {
    this._gtmEventService.clickSearchResultEvent(description);
    this.initialDateSearch.map((item: any) => {
      item.select = item.sku === value ? true : false;
    });
    this._localStorageService.setItem('listProductSearch', this.initialDateSearch);
    this.router.navigate(['/tienda/busqueda'], { queryParams: { productos: value } });
    this.removeOpacityAutocomplete();
  }

  addOpacityAutocomplete() {
    this.renderer.addClass(this.search_opacity.nativeElement, "class-opacity");
    this.openedStart();
  }

  removeOpacityAutocomplete() {
    this.renderer.removeClass(this.search_opacity.nativeElement, "class-opacity");
    this.closedStart();
  }
  openedStart() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  closedStart() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.ViewCar.close();
  }
  countProduct(e) {
    const replace = e.e.replace(/[^0-9-.]/g, '');
    let countFormat = parseFloat(replace)
    let tempCount: any = 0
    if (isNaN(countFormat) || countFormat == 0) {
      tempCount = ''
      countFormat = 0
    } else if (countFormat < 0) {
      countFormat = countFormat * -1;
      tempCount = UtilsService.formatNumber(countFormat)
    }
    else if (countFormat > 999999) {
      tempCount = '999,999'
      countFormat = 999999
    } else {
      tempCount = UtilsService.formatNumber(countFormat)
    }
    this.searchAndModifyProduct(tempCount, countFormat, e.p)
    this.ref.detectChanges()
  }

  searchAndModifyProduct(value, inputValue, p) {
    const cars = this.carsState.cars.map((car) => {
      const products = car.products.map(product => {
        let totalPrice = product.totalPrice;
        if (product.sku == p.sku) {
          totalPrice = UtilsService.formatTotalNumber((inputValue * parseFloat(p.salePrice)).toFixed(2))
          return {
            ...product,
            count: value,
            totalPrice: totalPrice
          }
        } else {
          return { ...product }
        }
      })
      return {
        ...car,
        products: products
      };
    })
    this.store.dispatch(shoppingActions.setCar({ cars }))
  }


  deleteProduct(p) {
    this._gtmEventService.deleteProductCarEvent("X", p)
    const cars = this.carsState.cars.map((car) => {
      const products = car.products.filter(product => product.sku != p.sku)
      return {
        ...car,
        products: products
      }
    })
    this.store.dispatch(shoppingActions.setCar({ cars }))
  }

  deleteCar() {
    this._gtmEventService.deleteProductCarEvent("Vaciar carrito")
    const cars = this.carsState.cars.map((car) => {
      if (car.unitBussines == this.unitSelect.name) {
        return {
          ...car,
          products: []
        }
      } else {
        return {
          ...car,
        }
      }
    })
    this.store.dispatch(shoppingActions.setCar({ cars }))
    this.ViewCar.toggle();
  }

  openCar() {
    this.showCarComponent.loadCar();
    this._gtmEventService.clickMyCarEvent();
    if(this.router.url != '/tienda/mi-carrito'){
      this.ViewCar.toggle();
      this.ViewCar.autoFocus = false;
    }
  }

  openSnackBar(text) {
      this._snackBar.open(text, 'Entendido', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['snackBar-content'],
        duration: 5000,
      });
  }

  redirect() {
    this.router.navigate(['/tienda/mi-carrito'])
    this.closedStart();
  }

  selectConfirmation(stock:boolean) {
    stock && this.openSnackBar("Cambiaste las cantidades de tus productos, no olvides comprobar su disponibilidad.");
    this.redirect()
    this.ViewCar.close();
  }

  //obteniendo data de cuenta corriente para modal de carrito

  getCheckingAccount() {
        this.accountsSuscription$ = this._checkingAccountService
          .getCheckingAccount(this._localStorageService.getItem('user')?.code)
          .pipe(take(1))
          .subscribe({
            next: (res: any) => {
              if (res) {
                this.resAccounts = res.tCab;
                let valueSelect =this.resAccounts.filter(element => element.name === this.unitSelect?.name)
                this.selectAccount(valueSelect[0]);
      
              } else {
                this.resAccounts = null;
              }
            },
            error: (err: Error) => {
              this.resAccounts = null;
            },
          });
    
  }
  selectAccount(value) {
    if(this.resAccounts){
      this.dataCurrentAccount = this.resAccounts.filter(element => element.name === value?.name);
    }

  }
 
  unsubscribeFromStoreAccount() {
    if (this.accountsSuscription$) {
      this.accountsSuscription$.unsubscribe();
    }
  }
  PROFILE_MENU_USER(value) {
    //dinamico
    this.iconProfileUser = [
      { value_menu: (value?.isAdvance && this.user.type === 'Vendedor'), cardNew: false, name: 'Avance de Ventas', image: 'allies', hover: false },
      { value_menu: value?.isBenefit, cardNew: false, name: 'Reconocimientos', image: 'reconocimiento', hover: false },
      { value_menu: true, cardNew: false, name: 'Historial de compras', image: 'historial', hover: false },
      { value_menu: true, cardNew: false, name: 'Cuenta corriente', image: 'account', hover: false },
      { value_menu: true, cardNew: false, name: 'Comprobante electrónico', image: 'comprobante', hover: false },
      { value_menu: value?.isAlly, cardNew: false, name: 'Aliados', image: 'allies', hover: false },
      { value_menu: true, cardNew: true, name: 'Acuerdos comerciales', image: 'trade-agreements', hover: false },
      { value_menu: true, cardNew: false, name: 'Cambiar contraseña', image: 'change-password', hover: false },
      { value_menu: true, cardNew: false, name: 'Cerrar sesión', image: 'logout', hover: false }]
  }
}
