<!-- Contenido principal -->
<div class="container" *ngIf="!formLoading">
  <div class="content-body d-flex justify-content-center">
    <section class="show-box-shadow" *ngIf="questions">
      <div class="container-form">
        <!-- Título y descripción del formulario -->
        <div class="ph-24 pv-16">
          <div class="mt-16 fts-18 fontw-400 font-regular">
            {{questions[currentQuestionIndex]?.descriptionGeneral}}
          </div>
        </div>

        <hr class="dividing-line-solid mb-16 mt-0 mh-24" />

        <!-- Sección de preguntas -->
        <div class="mh-24">
          <div class="fts-24 fontw-700 font-bold-dali color-neutral-medium">
            <span class="color-title-custom"
              >{{ formatSectionNumber(sectionNumber) }}</span
            >
            / <span>{{ formatTotalSections(totalSections) }}</span>
          </div>
          <div class="fts-18 fontw-700 color-title-custom font-bold-dali mb-16">
            {{questions[currentQuestionIndex]?.titleQuestion}}
          </div>
          <div class="fts-16 fontw-400 description font-regular mb-16">
            {{questions[currentQuestionIndex]?.description}}
          </div>
          <div class="container-body pb-3">
            <ng-container *ngIf="questions.length > 0">
              <!-- Componente para renderizar la pregunta -->
              <ng-container [ngSwitch]="questions[currentQuestionIndex]?.type">
                <!-- Campo de fecha -->

                <mat-form-field
                  class="datepicker-input vw-100"
                  *ngSwitchCase="'datepicker'"
                >
                  <!-- <p class="placeholder" *ngIf="!selectedDate">Elige la fecha (opcional)</p> -->
                  <input
                    class="input-picker font-regular fts-16 color-title-custom"
                    matInput
                    [matDatepicker]="picker"
                    [(ngModel)]="formData[questions[currentQuestionIndex]?.id]"
                    name="question{{ questions[currentQuestionIndex]?.id }}"
                  />

                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    [disabled]="false"
                  >
                    <mat-icon
                      [svgIcon]="false ? 'datepicker_icon_disabled' : 'datepicker_icon'
                      "
                      matDatepickerToggleIcon
                    ></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker [disabled]></mat-datepicker>
                </mat-form-field>

                <!-- Área de texto -->
                <textarea
                  *ngSwitchCase="'textArea'"
                  [(ngModel)]="formData[questions[currentQuestionIndex].id]"
                  [disabled]="false"
                  name="question{{ questions[currentQuestionIndex].id }}"
                  class="textArea"
                  maxlength="5000"
                >
					{{questions[currentQuestionIndex].options}}
				</textarea
                >

                <!-- Grupo de botones de opción -->
                <div *ngSwitchCase="'radioButton'">
                  <div
                    class="vw-100 datepicker-input mb-16 d-flex align-items-center"
                  >
                    <button mat-icon-button matPrefix>
                      <img src="/assets/svg/forms/search.svg" alt="search" />
                    </button>
                    <input
                      matInput
                      placeholder="Busca en la lista."
                      class="input-search fontw-400 font-regular input-search"
                      [(ngModel)]="searchTextRadioButton"
                      (input)="filterOptions(questions[currentQuestionIndex].options)"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      *ngIf="searchTextRadioButton"
                      (click)="deleteTextSearchRadio(questions[currentQuestionIndex].options)"
                    >
                      <img src="/assets/svg/forms/delete.svg" alt="search" />
                    </button>
                  </div>

                  <mat-radio-group
                    [(ngModel)]="formData[questions[currentQuestionIndex].id]"
                    name="question{{ questions[currentQuestionIndex].id }}"
                  >
                    <div
                      *ngFor="let option of filteredOptions[questions[currentQuestionIndex].id]"
                      class="custom-radio mb-8"
                    >
                      <mat-radio-button
                        [value]="option"
                        class="radio-button-custom font-regular fontw-400 fts-14"
                        [disabled]="false"
                      >
                        {{ option }}
                      </mat-radio-button>
                    </div>

                    <div
                      *ngIf="filteredOptions[questions[currentQuestionIndex].id].length === 0"
                    >
                      <app-message-empty-error
                        [messageError]="searchMessageFormGeneralNull"
                      ></app-message-empty-error>
                    </div>
                  </mat-radio-group>
                </div>

                <!-- Lista de verificación -->
                <div *ngSwitchCase="'checklist'">
                  <div
                    class="vw-100 datepicker-input mb-16 d-flex align-items-center"
                  >
                    <button mat-icon-button matPrefix>
                      <img src="/assets/svg/forms/search.svg" alt="search" />
                    </button>
                    <input
                      matInput
                      placeholder="Busca en la lista."
                      class="input-search fontw-400 font-regular fts-16"
                      [(ngModel)]="searchTextCheckbox"
                      (input)="filterOptionsCheckbox(questions[currentQuestionIndex].options)"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      *ngIf="searchTextCheckbox"
                      (click)="deleteTextSearch(questions[currentQuestionIndex].options)"
                    >
                      <img src="/assets/svg/forms/delete.svg" alt="search" />
                    </button>
                  </div>
                  <div
                    *ngFor="let option of filteredOptionsCheckbox[questions[currentQuestionIndex].id]"
                  >
                    <!-- Añade el evento (change) para llamar a onCheckboxChange y establece [checked] basado en el valor actual -->
                    <mat-checkbox
                      [checked]="formData[questions[currentQuestionIndex].id]?.selectedOptions?.[option]"
                      [disabled]="false"
                      class="custom-checkbox mb-8 font-regular fontw-400 fts-14"
                      (change)="onCheckboxChange(option)"
                    >
                      {{ option }}
                    </mat-checkbox>
                  </div>
                  <div
                    *ngIf="filteredOptionsCheckbox[questions[currentQuestionIndex].id].length === 0"
                  >
                    <app-message-empty-error
                      [messageError]="searchMessageFormGeneralNull"
                    ></app-message-empty-error>
                  </div>
                </div>

                <!-- Área de texto opcional -->
                <div *ngSwitchCase="'optionalTextArea'">
                  <div
                    *ngFor="let option of questions[currentQuestionIndex].options; let i = index"
                  >
                    <div
                      class="custom-optionalTextArea mb-8 d-flex align-items-center"
                    >
                      <div class="vw-100">
                        <mat-checkbox
                          (change)="onCheckboxChange(option)"
                          [checked]="formData[questions[currentQuestionIndex].id]?.selectedOptions[option]"
                          class="fts-14 fontw-400 line-height-24"
                        >
                          {{ option }}
                        </mat-checkbox>
                        <div
                          class="mt-8 fts-14 vw-100"
                          *ngIf="formData[questions[currentQuestionIndex].id]?.selectedOptions[option]"
                        >
                          <textarea
                            [disabled]="false"
                            name="question{{ questions[currentQuestionIndex].id }}"
                            class="textArea vw-100 fts-14"
                            placeholder="Describe tu respuesta aquí."
                            [(ngModel)]="formData[questions[currentQuestionIndex].id]?.descriptions[option]"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Carga de archivos -->
                <div *ngSwitchCase="'uploadFile'" class="ta-c">
                  <div
                    (drop)="onFilesDropped($event, questions[currentQuestionIndex].id)"
                    (dragover)="onDragOver($event)"
                    (click)="formData[questions[currentQuestionIndex].id]?.length < 5 && fileInput.click()"
                    [style.cursor]="formData[questions[currentQuestionIndex].id]?.length >= 5 ? 'not-allowed' : 'pointer'"
                  >
                    <input
                      type="file"
                      #fileInput
                      (change)="onFilesSelected($event, questions[currentQuestionIndex].id)"
                      multiple
                      style="display: none;"
                    />
                    <div
                      class="dashed-custom"
                      [ngClass]="{'disabled-state': formData[questions[currentQuestionIndex].id]?.length >= 5}"
                    >
                      <div class="d-flex jc-center ai-c container-img">
                        <img
                          [src]="formData[questions[currentQuestionIndex].id]?.length >= 5 ? '/assets/svg/forms/upload-disabled.svg' : '/assets/svg/forms/upload.svg'"
                          alt="Icono de carga"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div
                        class="fts-16 font-regular mt-8 text-description"
                        [ngClass]="{'text-description-disabled': formData[questions[currentQuestionIndex].id]?.length >= 5}"
                      >
                        <span
                          class="text-select"
                          [ngClass]="{'text-select-disabled': formData[questions[currentQuestionIndex].id]?.length >= 5}"
                        >
                          Selecciona
                        </span>
                        o arrastra tu archivo
                      </div>
                    </div>
                  </div>
                  <div
                    class="fts-14 fontw-400 font-regular upload-files-description"
                  >
                    Formatos: .doc, .docx, .pdf, .jpg, .jpeg, .png, .heic,
                    .heif, .webp Máx: 5 archivos - 5 MB por archivo.
                  </div>

                  <!-- Div que muestra los archivos cargados -->
                  <div
                    class="w-100"
                    *ngIf="formData[questions[currentQuestionIndex].id]?.length"
                  >
                    <div
                      *ngFor="let file of formData[questions[currentQuestionIndex].id]; let i = index"
                      class="uploaded-file fts-14 fontw-400 font-regular"
                      [ngClass]="{ 'oversized-file': file.isOversized }"
                    >
                      <div
                        *ngIf="!file.isLoaded"
                        class="uploaded-file-content d-flex align-items-center justify-content-between"
                      >
                        <span style="text-align: left; margin: 0 0.5rem;">
                          {{ file.name }} - {{ (file.size / 1024 /
                          1024).toFixed(2) }} MB
                        </span>
                        <mat-progress-bar
                          mode="determinate"
                          [value]="file.progress"
                          class="loading-bar"
                        ></mat-progress-bar>
                      </div>
                      <div
                        *ngIf="file.isLoaded"
                        class="uploaded-file-content d-flex align-items-center justify-content-between"
                      >
                        <div class="file-info d-flex align-items-center">
                          <img
                            *ngIf="!file.isOversized"
                            src="assets/svg/upload-icon-check.svg"
                            alt="check"
                            class="icon-check"
                          />
                          <span
                            class="file-name"
                            style="text-align: left; margin: 0 0.5rem;"
                          >
                            {{ file.name }} - {{ (file.size / 1024 /
                            1024).toFixed(2) }} MB
                          </span>
                        </div>
                        <div class="d-flex align-items-center">
                          <!-- Mensaje de advertencia si el archivo supera el límite -->
                          <span
                            *ngIf="file.isOversized"
                            class="oversized-warning"
                          >
                            Peso máx 5 MB
                          </span>
                          <img
                            src="assets/svg/upload-icon-delete.svg"
                            alt="delete"
                            class="icon-delete"
                            (click)="removeFile(i)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Desplegable -->
                <div
                  *ngSwitchCase="'combobox'"
                  class="d-flex align-items-center"
                >
                  <mat-form-field
                    appearance="outline"
                    class="vw-100 select-general font-bold-dali fontw-700 fts-16"
                    color="accent"
                  >
                    <mat-select
                      [(ngModel)]="formData[questions[currentQuestionIndex].id]"
                      name="selectCombobox"
                      [ngClass]="{ 'hide-select-arrow': true }"
                      #selectPanel="matSelect"
                      (opened)="onSelectPanelOpened()"
                      (closed)="onSelectPanelClosed(formData[questions[currentQuestionIndex].id])"
                      floatLabel="never"
                      placeholder="Elige una opción."
                    >
                      <div class="custom-combobox">
                        <div
                          class="vw-100 datepicker-input mb-16 d-flex align-items-center"
                        >
                          <button mat-icon-button matPrefix>
                            <img
                              src="/assets/svg/forms/search.svg"
                              alt="search"
                            />
                          </button>
                          <input
                            matInput
                            placeholder="Busca en la lista."
                            class="input-search fontw-400 font-regular fts-16"
                            [(ngModel)]="searchTextSelect"
                            (input)="filterOptionsSelect(questions[currentQuestionIndex].options)"
                          />
                          <button
                            mat-icon-button
                            matSuffix
                            *ngIf="searchTextSelect"
                            (click)="deleteTextSearchCombobox(questions[currentQuestionIndex].options)"
                          >
                            <img
                              src="/assets/svg/forms/delete.svg"
                              alt="search"
                            />
                          </button>
                        </div>
                        <mat-option
                          *ngFor="let option of filteredSelect[questions[currentQuestionIndex].id]"
                          [value]="option"
                          (click)="selectOption(option)"
                        >
                          {{ option }}
                        </mat-option>
                        <div
                          *ngIf="filteredSelect[questions[currentQuestionIndex].id].length === 0"
                        >
                          <app-message-empty-error
                            [messageError]="searchMessageFormGeneralNull"
                          ></app-message-empty-error>
                        </div>
                      </div>
                    </mat-select>

                    <span
                      class="material-symbols-rounded"
                      [ngClass]="
                false
                  ? 'color-disabled'
                  : 'color-active'
              "
                    >
                      {{ isOpen ? "expand_less" : "expand_more" }}
                    </span>
                  </mat-form-field>
                </div>

                <!-- Vista de lista -->
                <div *ngSwitchCase="'listView'">
                  <div
                    class="d-flex justify-content-between container-listView"
                  >
                    <div
                      *ngFor="let num of [1, 2, 3, 4, 5]"
                      (click)="formData[questions[currentQuestionIndex].id] = num"
                      class="button-listView"
                      (click)="selectNumber(num)"
                      [ngClass]="{'selected': isSelected(num)}"
                    >
                      {{ num }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!-- Pie de página -->
<div class="footer d-flex align-items-center" *ngIf="!formLoading">
  <!-- Contenedor de botones de navegación -->
  <div class="container d-flex justify-content-center">
    <div class="d-flex justify-content-between ai-c container-button">
      <button
        class="btn-back-new p-12 font-regular fts-14 d-flex ai-center"
        (click)="previousQuestion()"
        [disabled]="currentQuestionIndex === 0"
      >
        <mat-icon class="fts-16 d-flex ai-center">arrow_back</mat-icon>
        <span class="ml-8">Atrás</span>
      </button>
      <button
        class="btn-green p-12 font-regular fts-14 d-flex ai-center justify-content-center"
        (click)="currentQuestionIndex === questions.length - 1 ? onSubmit() : nextQuestion()"
        [disabled]="isNextButtonDisabled() || isSubmitting"
      >
        <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
        <span class="ml-8">
          {{ currentQuestionIndex === questions.length - 1 ? 'Enviar' :
          'Siguiente' }}
        </span>
        <mat-icon
          *ngIf="currentQuestionIndex !== questions.length - 1"
          class="fts-16 d-flex ai-center"
        >
          arrow_forward
        </mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- Skeleton -->
<div class="container" *ngIf="formLoading">
  <div class="content-body-skeleton d-flex justify-content-center">
    <section class="show-box-shadow" *ngIf="questions">
      <div class="container-form-skeleton">
        <div class="ph-24 pv-8">
          <div class="mt-16 fts-18 fontw-400 font-regular">
            <div>
              <ngx-skeleton-loader
                count="2"
                appearance="line"
                [theme]="{height: '16px'}"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="skeleton-50">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{height: '16px'}"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <hr class="dividing-line-solid mt-0 mh-24" />
        <div class="ph-24 pv-8">
          <div class="mt-16 fts-18 fontw-400 font-regular">
            <div class="skeleton-25">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{height: '29px'}"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="skeleton-65">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{height: '16px'}"
              >
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
