<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fts-12">
	<!-- Fecha de creación -->
	<ng-container matColumnDef="Fecha de creación">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-fecha-creacion">Fecha de creación</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '72px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Estado -->
	<ng-container matColumnDef="Estado">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-estado">Estado</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '78px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Agenda -->
	<ng-container matColumnDef="Agenda">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-large">Agenda</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '118px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Categoría -->
	<ng-container matColumnDef="Categoría">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-large">Categoría</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '102px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Planes de acción / Actividades -->
	<ng-container matColumnDef="Planes de acción / Actividades">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-large">Planes de acción / Actividades</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '214px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Notas del vendedor -->
	<ng-container matColumnDef="Notas del vendedor">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-notas">Notas del vendedor</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '16px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Mis notas -->
	<ng-container matColumnDef="Mis notas">
	  <th mat-header-cell *matHeaderCellDef class="mat-column-mis-notas">Mis notas</th>
	  <td mat-cell *matCellDef="let element">
		<div class="d-flex justify-content-center align-items-center">
		  <ngx-skeleton-loader
			count="1"
			appearance="line"
			[theme]="{
			  height: '16px',
			  width: '16px',
			  'border-radius': '4px',
			  'margin-bottom': '0px',
			  display: 'flex'
			}"
		  ></ngx-skeleton-loader>
		</div>
	  </td>
	</ng-container>
  
	<!-- Definir las filas -->
	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  